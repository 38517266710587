import apiAsync from '../index'
import paramsWrap from '@/axios/params-wrap'
import { UploadFile } from '@/interface/upload'
import signWrap from '@/apollo-client/sign'

export default {
  uploadFile: (data: UploadFile) => {
    return apiAsync({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data;charset=UTF-8', // json格式数据
        ...signWrap()
      },
      url: process.env.VUE_APP_BASE_API,
      data: paramsWrap.UPLOAD_WRAP(data),
      loading: true,
      catchs: true,
      toast: true
    })
  }
}

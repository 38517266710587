export const UPLOAD_QUERY = `mutation($params: OpenAccountUploadFileInput!){
  openAccountUploadFile(param: $params)
  {
      data
      ok
      reason {
          err
          desc
      }
  }
} `

// 图片类型
// eslint-disable-next-line no-shadow
export enum ImageType {
  ID_CARD_FACE = 101,
  ID_CARD_BACK = 102,
  BANK = 201,
  SIGN = 301,
  SIGN_INFO = 302,
  ID_CARD_HANDHELD = 401
}

// 开户证件类型
// eslint-disable-next-line no-shadow
export enum IdKindType {
  MAINLAND = 1,
  OVERSEAS = 2
}

// 图片来源
// eslint-disable-next-line no-shadow
export enum Source {
  H5 = 1,
  APP = 2,
  WEB = 3
}

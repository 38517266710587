import { UPLOAD_QUERY } from '@/axios/define'

import { UploadFile } from '@/interface/upload'

import * as storage from '@/utils/storage'

interface CommonWrap {
  openType: number,
  openStatus: number,
  btnText: string,
  remark: string,
  step: number
}

/**
 * 请求层包裹
 * @param data
 * @returns
 */
const WRAP = <T>(data: T): { params: T } => ({
  params: {
    ...data
  }
})

// 开户公共缓存表
const OPEN_COMMON_WRAP = (data: any) => ({
  params: {
    formType: 'openAccount',
    meta: 'open_common',
    content: JSON.stringify(data)
  }
})

const openAccountTypeMap:any = {
  openAccount: 1, // 普通开户
  openImmigrantAccount: 9 // 移民开户
}

const UPLOAD_WRAP = (data: UploadFile) => {
  const formType = storage.get('formType') || 'openAccount'
  const { file, imageType, businessType, idKindType, source, kindSource } = data
  const obj = {
    imageType,
    businessType,
    idKindType,
    source,
    kindSource,
    openAccountType: openAccountTypeMap[formType] || 1
  }
  const form = new FormData()
  form.append('query', UPLOAD_QUERY)
  form.append('variables', 'params')
  form.append('file', file)
  form.append('expvars', JSON.stringify(obj))
  return form
}

export default {
  WRAP,
  UPLOAD_WRAP,
  OPEN_COMMON_WRAP
}

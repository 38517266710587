import { AxiosResponse } from 'axios'
import { Dialog } from 'vant'
import instance from './instance'
import { isInAppWebview } from '@/utils/native-app'
import redirectUrl from '@/utils/redirect-url'
import { Message } from '@/components/Message'

/**
 * 接口请求实例
 * 适用 针对返回结果数据字段
 * { code, message, result }
 *
 * 封装API接口请求方法, 针对aixos实例方法调用
 * options = {
 *   url：请求地址
 *   method: 请求使用的方法，如 GET、POST
 *   data:  请求数据，针对post
 *   params: 请求数据，针对get
 *   loading: 请求自定义是否前后自动加载与关闭loading效果, 默认 false
 *   catchs: 异常处理，接口抛出的异常是否自己处理：true 是，false 否
 *           由公共方法统一处理展示在控制台 默认 false
 *   toast: 异常弹框，true 是，false 否
 *          由公共方法统一处理优化显示给用户 默认 true
 * }
 */

const apiAsync = function (options: any) {
  // 获取自定义配置参数
  const { loading = false, catchs = false, toast = true, ...obj } = options
  return new Promise((resolve, reject) => {
    instance(obj)
      .then(res => {
        console.log('obj', obj)
        const data = <AxiosResponse>res.data
        resolve(data.data)
      })
      .catch(error => {
        const { message } = error
        console.log('message', message)
        const signError = (message.includes(542) || message.includes(543) ||
        message.includes(544) || message.includes(546) || message.includes(547))
        const isApp = isInAppWebview()
        if (signError) {
          Message({ text: 'sign异常，请稍后重试！', direction: 'vertical' })
        } else if (message.includes(545)) {
          if (isApp) {
            Dialog.alert({
              title: '温馨提示',
              message: 'Token异常，请退出APP重新登陆！'
            })
          } else {
            Message({ text: 'session失效，请重新登陆！', direction: 'vertical' })
            redirectUrl()
          }
        } else {
          Message({ text: '请求异常，请稍后重试！', direction: 'vertical' })
        }
        reject(new Error('axios异常'))
      })
  })
}

export default apiAsync

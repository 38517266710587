import axios from 'axios'

// 实例化
const instance = axios.create({
  responseType: 'json', // 数据格式
  withCredentials: false // 是否允许带cookie这些
})

// http请求拦截器<pendding>
instance.interceptors.request.use(
  config => config,
  error => Promise.reject(error)
)

// http响应拦截器<done>
instance.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export default instance

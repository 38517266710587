import pako from 'pako'

export const dataURLtoFile = function (dataurl: string, filename: string) {
  // 获取到base64编码
  const arr = dataurl.split(',')
  // 将base64编码转为字符串
  const bstr = window.atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n) // 创建初始化为0的，包含length个元素的无符号整型数组
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, {
    type: 'image/png'
  })
}

export const Uint8ToString = function (u8a: any) {
  const CHUNK_SZ = 0x8000
  const c = []
  for (let i = 0; i < u8a.length; i += CHUNK_SZ) {
    c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)))
  }
  return c.join('')
}

export const unzip = (b64Data: any) => {
  const strData = atob(b64Data)
  const charData = strData.split('').map((x: string) => x.charCodeAt(0))
  const binData = new Uint8Array(charData)
  const array = pako.inflate(binData)
  const b64encoded = btoa(Uint8ToString(array))
  return b64encoded
}

// file to base64
export const toBase64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})
